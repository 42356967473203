function Footer() {
  return (
    <div className="text-center">
      <p><small>©2021 Anthony Badalamenti</small></p>
      <p><small>Phase 2 Student Project for Flatiron School Software Development Course</small></p>
    </div>
  );
}


export default Footer